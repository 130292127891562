// The idea of this helper is to have common methods that define how to update single vehicle offer on our store
// it can be used from list, vdp or some other places so we keep consistency between different slices

import { BID_SALE_AUCTION_STATUS } from 'constants/bidSale';
import dayjs from 'dayjs';
import { decodeVehicle } from 'helpers/timedSales';
import { isOlderVersion } from 'helpers/timedSales/versionChecker';
import { isCurrentUserTheTopOfferer } from 'helpers/marketplace/myOfferStatus';

export const updateOfferStatus = ({
	currentOfferState,
	updatedVehicleId,
	updatedOfferState: encodedChanges,
	loggedUser,
}) => {
	const changes = decodeVehicle(encodedChanges);
	const { best_offer_offerer_dealership_id } = changes;

	if (!currentOfferState || isOlderVersion({ incomingChanges: changes, storedState: currentOfferState })) return;

	if (updatedVehicleId && changes.id !== updatedVehicleId) delete changes.id;

	const isUserTopBidder = isCurrentUserTheTopOfferer({
		currentDealershipOrAnySubordinateIdThatOffered:
			currentOfferState.offerer_dealership_id || loggedUser?.dealership_id,
		best_offer_offerer_dealership_id,
		loggedUser,
	});

	if (isUserTopBidder) {
		if (changes.bid_sale_auction_status !== BID_SALE_AUCTION_STATUS.pending && changes.best_offer_amount) {
			changes.my_offer_amount = changes.best_offer_amount;
		}
		if (changes.best_offer_max_bid_amount) {
			changes.my_offer_max_bid_amount = changes.best_offer_max_bid_amount;
		}
	}

	const notOvertimeStatus =
		changes.bid_sale_auction_status && changes.bid_sale_auction_status !== BID_SALE_AUCTION_STATUS.overtime;
	const extensionTimeWasAdded =
		changes?.bid_sale_auction_extended_until &&
		currentOfferState?.bid_sale_auction_extended_until &&
		dayjs(changes?.bid_sale_auction_extended_until).isAfter(
			dayjs(currentOfferState.bid_sale_auction_extended_until),
		);
	// set isOvertimeCompletedButServerDidNotResolveTheSell to false if a new offer is received on overtime and it extends auction_extended_until field
	if (notOvertimeStatus || extensionTimeWasAdded) {
		changes.isOvertimeCompletedButServerDidNotResolveTheSell = false;
	}

	return changes;
};

export const setOvertimeCompleted = ({ currentOfferState }) => {
	// isOvertimeCompletedButServerDidNotResolveTheSell is a local field (doesn't come from BE) only to keep track
	const vehicleToUpdate = {
		...currentOfferState,
		isOvertimeCompletedButServerDidNotResolveTheSell: true,
	};

	return vehicleToUpdate;
};
