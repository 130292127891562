import { FlexBox, colors, Body, Button } from '@Backlot-Cars/archie';
import OktaService from 'helpers/okta';
import { useEffect, useState } from 'react';

const OktaExistingSessionBanner = ({ setLoading }) => {
	const [isSessionActive, setIsSessionActive] = useState(false);

	const checkExistingSession = async () => {
		const thereIsAnActiveSession = await OktaService.getInstance().checkSession();
		setIsSessionActive(thereIsAnActiveSession);
	};

	const loginWithOktaForExistingSession = () => {
		setLoading(true);
		OktaService.getInstance().generateNewCodeForActiveSessions();
	};

	useEffect(() => {
		checkExistingSession();
	}, []);

	if (isSessionActive) {
		return (
			<FlexBox flexDirection="column" gap={8} background={colors.secondary} padding="10px" borderRadius={8}>
				<Body bold color="primaryText">
					You already have an active session on Okta
				</Body>
				<Button onClick={loginWithOktaForExistingSession}>Log in with Okta</Button>
			</FlexBox>
		);
	}
};

export default OktaExistingSessionBanner;
