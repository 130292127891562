export const MARKETPLACE_TAB = {
	marketplace: 'marketplace',
	offers: 'marketplace_offers',
	watchlist: 'marketplace_watchlist',
	hidden: 'marketplace_hidden',
};

const MARKETPLACE_SOURCE_TAB = {
	[MARKETPLACE_TAB.marketplace]: 'marketplace',
	[MARKETPLACE_TAB.offers]: 'offers',
	[MARKETPLACE_TAB.watchlist]: 'watchlist',
	[MARKETPLACE_TAB.hidden]: 'hidden',
};

export const getMarketplaceSourceTabFromTabName = (tabName) => MARKETPLACE_SOURCE_TAB[tabName];

export const VEHICLE_INVENTORY_TYPE = {
	rental: 'rental',
	company_car: 'company_car',
	off_lease: 'off_lease',
	reposession: 'reposession',
	dealer: 'dealer',
};

export const isMarketplaceTab = (tab) => tab === MARKETPLACE_TAB.marketplace;
