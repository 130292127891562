import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
	isWebSocketConnected: false,
	disablePooling: false,
	isPoolingInProgress: false,
	wasLastPoolSuccess: null,
	isLastRoundSync: false,
	lastRoundRetries: 0,
	syncedTabs: [],
	iterationCount: 0,
	// processing polling (done only when WS are connected and vehicles are in processing state)
	forceRefreshVehiclesInProcessing: false,
	// defensive Polling (done when WS are connected, just to ensure that if all above methods fail we update at least every x seconds)
	defensivePollingInterval: undefined, // zero equals no defensive polling
	isDefensivePollingInProgress: false,
};

export const auctionPooling = createSlice({
	name: 'auctionPooling',
	initialState,
	reducers: {
		updateWSConnectionState: (state, { payload }) => {
			const { isWebSocketConnected } = payload;

			if (isWebSocketConnected != null) {
				state.isWebSocketConnected = isWebSocketConnected;

				if (!isWebSocketConnected) {
					state.isPoolingInProgress = true;
					state.isLastRoundSync = false;
					state.lastRoundRetries = 0;
					state.iterationCount = 0;
					// all tabs may be out of sync on WS disconnection
					state.syncedTabs = [];
				}
			}
		},
		updateSyncedTabsAfterFetch: (state, { payload }) => {
			const { syncedTab } = payload;
			if (state.isWebSocketConnected && !state.syncedTabs.includes(syncedTab)) {
				state.syncedTabs = [...state.syncedTabs, syncedTab];
			}
		},
		updatePoolingState: (state, { payload }) => {
			const { wasLastPoolSuccess, syncedTab, defensivePollingInterval } = payload;
			state.wasLastPoolSuccess = wasLastPoolSuccess;
			state.defensivePollingInterval = defensivePollingInterval;
			if (!wasLastPoolSuccess && state.isLastRoundSync) {
				state.lastRoundRetries += 1;
			}
			// only update it when WS are reconnected
			if (
				wasLastPoolSuccess &&
				state.isWebSocketConnected &&
				syncedTab &&
				!state.syncedTabs.includes(syncedTab)
			) {
				state.syncedTabs = [...state.syncedTabs, syncedTab];
			}

			state.iterationCount += 1;
		},
		setIsLastRoundSync: (state, { payload }) => {
			const { isLastRoundSync } = payload;
			state.isLastRoundSync = isLastRoundSync;
		},
		disablePooling: (state) => {
			state.disablePooling = true;
		},
		resetState: (state) => {
			state.isPoolingInProgress = false;
		},
		updateForceRefreshVehiclesInProcessing: (state, { payload: forced }) => {
			state.forceRefreshVehiclesInProcessing = forced;
		},
		setIsDefensivePollingInProgress: (state, { payload: itsPollingInProgress }) => {
			state.isDefensivePollingInProgress = itsPollingInProgress;
		},
	},
});

export const {
	updateWSConnectionState,
	updateSyncedTabsAfterFetch,
	updatePoolingState,
	setIsLastRoundSync,
	resetState,
	disablePooling,
	updateForceRefreshVehiclesInProcessing,
	setIsDefensivePollingInProgress,
} = auctionPooling.actions;

export default auctionPooling.reducer;
