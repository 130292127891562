export const VEHICLE_STATUS = {
	active: 'active',
	sold: 'sold',
	expired: 'expired',
	onHold: 'on_hold',
	republished: 'republished',
	pendingCheckout: 'pending_checkout',
	deleted: 'deleted',
	pending: 'pending',
	inactive: 'inactive',
	repost: 'repost',
};

export const INACTIVE_STATUS = [
	VEHICLE_STATUS.inactive,
	VEHICLE_STATUS.onHold,
	VEHICLE_STATUS.pending,
	VEHICLE_STATUS.expired,
];

export const SALE_TYPE = {
	auction: 'auction',
	marketplace: 'marketplace',
};
