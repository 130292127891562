/* eslint-disable camelcase */
import { authenticatedRestClient, withCookiesRestClient } from 'helpers/restclient';
import { getAuthHeaders } from 'helpers/restclient/auth';
import { apiV3Headers } from 'helpers/restclient/headers';

export default class UserSessionService {
	static instance = new UserSessionService();

	_endpoints = {
		logout: '/api/users/sessions',
		groupAccounts: '/api/sellers/masters/users',
		changeAccount: '/api/masters/sessions',
		reloadUser: '/users/cookie',
		getUserNavigationInfo: '/api/users/navigation',
		signIn: '/api/users/sessions',
		refreshAccessToken: '/api/users/sessions/refresh',
		oktaProfiles: '/api/okta_profiles',
	};

	constructor() {
		this.restClient = authenticatedRestClient;
		this.withCookiesRestClient = withCookiesRestClient;
	}

	logout = (user) => this.restClient.delete(`${this._endpoints.logout}`, {}, getAuthHeaders(user));

	getGroupAccounts = async (user) =>
		await this.restClient.get(this._endpoints.groupAccounts, {}, getAuthHeaders(user));

	changeAccount = async (id, user) => {
		const { data } = await this.restClient.post(
			`${process.env.RELATIVE_BASE_URL}${this._endpoints.changeAccount}`,
			{ user_id: id },
			getAuthHeaders(user),
		);
		return data?.redirect_path;
	};

	reloadUser = async (user) => {
		await this.restClient.post(
			`${process.env.RELATIVE_BASE_URL}${this._endpoints.reloadUser}`,
			{},
			getAuthHeaders(user),
		);
	};

	getUserNavigationInfo = async (accessToken) =>
		await this.withCookiesRestClient.get(
			`${process.env.RESTURL_BASE}${this._endpoints.getUserNavigationInfo}`,
			undefined,
			{
				Accept: 'application/json, application/vnd.backlotcars.v3',
				Authorization: `Token token=${accessToken}`,
			},
		);

	signIn = (email, password, utmParams, oktaCode) => {
		const requestBody = oktaCode ? {} : { user: { email, password } };
		const queryParams = utmParams ? `&${utmParams}` : '';
		return this.withCookiesRestClient.post(`${this._endpoints.signIn}?${queryParams}`, requestBody, {
			Accept: 'application/json, application/vnd.backlotcars.v3',
			'Content-Type': 'application/json',
			'Okta-Access-Token': oktaCode,
		});
	};

	refreshAccessToken = (refreshToken, headers) => {
		return this.withCookiesRestClient.post(
			`${process.env.RESTURL_BASE}${this._endpoints.refreshAccessToken}`,
			{},
			{
				Accept: 'application/json, application/vnd.backlotcars.v3',
				Cookie: `;refresh_token=${refreshToken}`,
				...headers,
			},
		);
	};

	refreshAccessTokenLegacy = (headers) => {
		return this.withCookiesRestClient.post(
			`${process.env.RESTURL_BASE}${'/api/users/sessions/web_jwt'}`,
			{},
			headers,
		);
	};

	checkOktaProfile = ({ username, password }) => {
		return this.withCookiesRestClient.post(
			`${process.env.RESTURL_BASE}${this._endpoints.oktaProfiles}`,
			{ username, password },
			apiV3Headers,
		);
	};

	confirmOktaProfileLink = ({ invitationToken }) => {
		return this.withCookiesRestClient.put(
			`${process.env.RESTURL_BASE}${this._endpoints.oktaProfiles}/${invitationToken}`,
			{},
			apiV3Headers,
		);
	};
}
