import { authenticatedRestClient } from 'helpers/restclient';
import { getAuthHeaders } from 'helpers/restclient/auth';
import { getListContextFromSearchListKey, getSourceTabFromSearchListKey } from 'helpers/search';

export default class BuyerOffersService {
	static instance = new BuyerOffersService();

	_endpoints = {
		offerRefresh: `/api/offer_refresh/query`,
	};

	constructor() {
		this.restClient = authenticatedRestClient;
	}

	offerRefresh = ({
		vehicles,
		loggedUser,
		focussedSearchListKey,
		isFeaturedVehicleCampaign,
		isVdp,
		reason,
		forceRefresh,
		includeSummary,
		summaryVersions,
	}) => {
		const endpoint = `${this._endpoints.offerRefresh}?reason=${reason}&include_summary=${Boolean(includeSummary)}`;
		const offerRefreshData = {
			context: isVdp ? 'vehicle_detail' : getListContextFromSearchListKey(focussedSearchListKey),
			source_tab: getSourceTabFromSearchListKey(focussedSearchListKey),
			featured_campaign: Boolean(isFeaturedVehicleCampaign),
			bid_sales: vehicles.map(({ bid_sale_id, version }) => ({
				id: bid_sale_id,
				version: forceRefresh ? undefined : version,
			})),
		};
		if (includeSummary) offerRefreshData.summary = summaryVersions || [];
		return this.restClient.post(endpoint, offerRefreshData, getAuthHeaders(loggedUser));
	};
}
