import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';

const buyerTimedSalesOfferSummarySelector = createSelector(
	({ buyerOfferSummary }) => buyerOfferSummary.stats.leaderCount,
	({ buyerOfferSummary }) => buyerOfferSummary.stats.outbiddedCount,
	({ buyerOfferSummary }) => buyerOfferSummary.stats.wonCount,
	({ buyerOfferSummary }) => buyerOfferSummary.stats.lostCount,
	({ buyerOfferSummary }) => buyerOfferSummary.stats.counterCount,
	({ buyerOfferSummary }) => buyerOfferSummary.loading,
	({ buyerOfferSummary }) => buyerOfferSummary.success,
	(leaderCount, outbiddedCount, wonCount, lostCount, counterCount, loading, success) => ({
		stats: {
			leaderCount,
			outbiddedCount,
			wonCount,
			lostCount,
			counterCount,
		},
		loading,
		success,
	}),
);
export const useBuyerOfferSummary = () => useSelector(buyerTimedSalesOfferSummarySelector);

export const offerSummaryWasLoadedSelector = createSelector(
	buyerTimedSalesOfferSummarySelector,
	({ success, stats }) => success || Object.values(stats).some((value) => value > 0),
);

const offerSummaryBidSaleIds = createSelector(
	({ buyerOfferSummary }) => buyerOfferSummary.bidSalesThatUserHasOfferer,
	(bidSalesThatUserHasOfferer) =>
		bidSalesThatUserHasOfferer.map((bidSalesThatIOfferedOn) => bidSalesThatIOfferedOn.bid_sale_id),
);
export const useOfferSummaryBidSaleIds = () => useSelector(offerSummaryBidSaleIds);

export const offerSummaryBidSaleIdsAndVersion = createSelector(
	({ buyerOfferSummary }) => buyerOfferSummary.bidSalesThatUserHasOfferer,
	(bidSalesThatUserHasOfferer) =>
		bidSalesThatUserHasOfferer.map((bidSalesThatIOfferedOn) => ({
			bid_sale_id: bidSalesThatIOfferedOn.bid_sale_id,
			version: bidSalesThatIOfferedOn.version,
		})),
);
