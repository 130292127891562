/* eslint-disable camelcase */
import PublicLayout from 'components/PublicSiteLayout/Base';
import TwoColumnPublicLayout from 'components/PublicSiteLayout/TwoColumn';
import urlsContext from 'contexts/urls/urlsContext';
import { getQueryParams } from 'helpers/queryParams';
import { getUser } from 'helpers/user/getUser';
import useTrackGoFromSms from 'hooks/useTrackGoFromSms';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useRouter } from 'next/router';
import SectionLanding from 'partials/sign_in/_sectionLanding.js';
import getRedirectionPath from 'partials/sign_in/getRedirectionPath';
import { useContext } from 'react';
import { wrapper } from 'store';

const dataTrackGOFromSMS = (utmMedium, utmSource, utmCampaign) => {
	return {
		utm_medium: utmMedium,
		utm_source: utmSource,
		utm_campaign: utmCampaign,
	};
};

const SignIn = () => {
	const { t } = useTranslation('signin');
	const router = useRouter();
	const { passwordForgot, signUpUrl } = useContext(urlsContext);
	const refererUrl = router.query.referer;
	const { utm_medium: utmMedium, utm_source: utmSource, utm_campaign: utmCampaign } = getQueryParams(refererUrl);

	useTrackGoFromSms('Guaranteed Offer SMS Login', dataTrackGOFromSMS(utmMedium, utmSource, utmCampaign));

	return (
		<PublicLayout
			isPublicPage
			id="signin"
			meta={{
				title: t('common:logIn'),
				description: 'BacklotCars is now OPENLANE! Access the ultimate wholesale solution. Click to sign in.',
			}}
		>
			<TwoColumnPublicLayout
				CardContent={
					<SectionLanding passwordForgot={passwordForgot} signUpUrl={signUpUrl} refererUrl={refererUrl} />
				}
			/>
		</PublicLayout>
	);
};

export const getServerSideProps = wrapper.getServerSideProps(() => async (ctx) => {
	const user = await getUser(ctx, false);

	if (user?.navigation) {
		const route = getRedirectionPath(user);

		if (route) {
			return {
				redirect: {
					permanent: false,
					destination: route,
				},
			};
		}
	}

	return {
		props: {
			...(await serverSideTranslations(ctx.locale, ['common', 'signin'])),
		},
	};
});

export default SignIn;
