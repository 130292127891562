import STATUS_CODES from 'constants/statusCodes';
import BuyerOffersService from 'services/marketplace/buyside/offers.service';
import { setNewBidSalesArray } from 'store/slices/offerSummary/buyerOfferSummary';

export const getRefreshDataForVehicleIds = async ({
	vehicles: vehiclesToRefresh,
	loggedUser,
	searchListKey,
	isFeaturedVehicleCampaign,
	includeSummary,
	offerSummaryWasLoaded,
	dispatch,
}) => {
	const shouldIncludeSummary = includeSummary && !offerSummaryWasLoaded;
	if (!vehiclesToRefresh.length && !shouldIncludeSummary) return [];

	const offerRefreshResult = await BuyerOffersService.instance.offerRefresh({
		vehicles: vehiclesToRefresh,
		loggedUser,
		focussedSearchListKey: searchListKey,
		reason: 'first_load',
		forceRefresh: true,
		isFeaturedVehicleCampaign,
		includeSummary,
	});
	if (offerRefreshResult?.status !== STATUS_CODES.OK || !offerRefreshResult?.data?.success) {
		throw new Error('Not able to get refreshed data for vehicles');
	}

	const {
		data: {
			data: { vehicles, summary },
		},
	} = offerRefreshResult;

	if (includeSummary && dispatch) dispatch(setNewBidSalesArray({ bidSalesArr: summary || [] }));

	const bidSaleIdToVehicleMap = vehicles.reduce(
		(accumulator, currentValue) => ({
			...accumulator,
			[currentValue.bid_sale_id]: currentValue,
		}),
		{},
	);

	return vehiclesToRefresh.map((vehicle) => ({
		...vehicle,
		...bidSaleIdToVehicleMap[vehicle.bid_sale_id],
	}));
};
