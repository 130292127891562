const SORTING_KEYS = ['order_by', 'direction', 'order_by_extension'];

export const filterTypes = {
	boolean: 'boolean_filter',
	textSearch: 'text_search_filter',
	range: 'range_filter',
	maxRange: 'max_range_filter',
	checkbox: 'checkbox_filter',
	radioButton: 'radio_button_filter',
	groupedCheckbox: 'grouped_checkbox_filter',
	segmented: 'segmented_filter',
};

export const multipleOptionsTypes = [filterTypes.groupedCheckbox, filterTypes.checkbox];

export const LIST_CONFIG_KEYS = [
	'closed_trade_network',
	'group_logo',
	'group_primary_color',
	'group_secondary_color',
	'private_marketplace_sale_only',
	'drivetime_pm_sale_only',
	'marketplace_ids',
];

export const ALLOWED_PARAMS_THAT_ARE_NOT_CONTAINED_ON_DEFAULT = {
	page: 1,
	saved_search_id: undefined,
};

const transformFilterToParam = ({ key, type, keys, default: defaultValue, options }) => {
	const params = {};
	if (keys) {
		params[keys.to] = defaultValue.to;
		if (type !== filterTypes.maxRange) {
			// maxRange should not include from value
			params[keys.from] = defaultValue.from;
		}
	} else if (type === filterTypes.radioButton || type === filterTypes.segmented) {
		// default values refer to key not value
		params[key] = options?.find((option) => option.key === defaultValue)?.value;
	} else if (type === filterTypes.checkbox && defaultValue?.length) {
		params[key] = defaultValue
			.map((defaultValueKey) => options?.find((option) => option.key === defaultValueKey)?.value)
			.filter((value) => Boolean(value)); // remove nullables
	} else if (type === filterTypes.groupedCheckbox) {
		params[key] = defaultValue || [];
	} else {
		params[key] = defaultValue;
	}
	return params;
};

export const getSectionDefaultParams = ({ filters }) => {
	if (!filters?.length) return {};

	return filters.reduce(
		(paramsFromFilters, filter) => ({ ...paramsFromFilters, ...transformFilterToParam(filter) }),
		{},
	);
};

/**
 * Given a sections array it returns an object whose keys are filter keys and value is default value.
 */
export const getDefaultParams = (sections) => ({
	...ALLOWED_PARAMS_THAT_ARE_NOT_CONTAINED_ON_DEFAULT,
	...sections.reduce(
		(accumulator, section) => ({
			...getSectionDefaultParams(section),
			...accumulator,
		}),
		{},
	),
});

/**
 * Given a sections array it returns an object whose keys are filter keys and value filterType.
 */
const getSectionFiltersType = ({ filters }) => {
	if (!filters?.length) return {};

	return filters.reduce((paramsFromFilters, filter) => ({ ...paramsFromFilters, [filter.key]: filter.type }), {});
};
const getFilterTypesFromSection = (sections) =>
	sections.reduce(
		(accumulator, section) => ({
			...getSectionFiltersType(section),
			...accumulator,
		}),
		{},
	);

const castForNullablesAndBooleans = {
	false: false,
	true: true,
	null: null,
	undefined: undefined,
};
// this is to cast types from url
const castTypes = (stringValue, filterType) => {
	if (!stringValue || typeof stringValue !== 'string') return stringValue;

	// special case arrays
	if (stringValue && (filterType === filterTypes.checkbox || filterType === filterTypes.groupedCheckbox)) {
		return stringValue.split(',').map((optionValue) => castTypes(optionValue, null));
	}

	const lowerCase = stringValue?.toLowerCase() || stringValue;

	if (Object.keys(castForNullablesAndBooleans).includes(lowerCase)) return castForNullablesAndBooleans[lowerCase];
	if (!Number.isNaN(Number(stringValue))) {
		return Number(stringValue);
	}
	return stringValue;
};

/**
 * We only use filters that contains to default params
 */
export const getSanitizedFilterParams = (params, defaultParams, sections) => {
	// add test for case when default params are nullable
	const filterTypes = getFilterTypesFromSection(sections);
	const defaultEntries = [...Object.keys(defaultParams), ...SORTING_KEYS];

	return {
		...defaultParams,
		...Object.entries(params)
			.filter(([key]) => defaultEntries.includes(key)) // only keep keys that exist on default
			.reduce((previousValue, [key, value]) => {
				return {
					...previousValue,
					[key]: castTypes(value, filterTypes[key]),
				};
			}, {}),
	};
};

export const getConfigParamsFromQuery = (params) => {
	return Object.fromEntries(Object.entries(params).filter(([key]) => LIST_CONFIG_KEYS.includes(key)));
};
