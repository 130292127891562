export const ACCESS_TOKEN = 'access_token';
export const REFRESH_TOKEN = 'refresh_token';
export const SESSION_ID = 'session_id';

export const getClientSideCookie = (name) => {
	try {
		if (typeof window !== 'undefined') {
			const cookieValue = document.cookie
				.split('; ')
				.find((row) => row.startsWith(`${name}=`))
				?.split('=')[1];

			return cookieValue;
		}
	} catch (error) {}
	return null;
};
