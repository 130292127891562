import { notifyError } from 'helpers/errorNotifier';

export const getPathFromURL = (url, defaultOnError) => {
	const defaultValue = defaultOnError || '/';
	try {
		const urlObj = new URL(url, process.env.BASE_URL);
		return `${urlObj.pathname}${urlObj.search}` || defaultValue;
	} catch (e) {
		return defaultValue;
	}
};

export const createAbsoluteUrlFromPath = (urlPath) => {
	let baseUrl = process.env.BASE_URL;
	if (typeof window !== 'undefined' && window?.location?.origin) {
		baseUrl = window.location.origin;
	}
	return `${baseUrl}${urlPath}`;
};

export const couldBePath = (path) => path.startsWith('/');

export const ensurePathToBeValid = (path) => (couldBePath(path) ? path : `/${path}`);

export const getUtmParamsString = (params) => {
	return getAllowedParamsString(params, ['utm_']);
};
export const getUtmAndAFCParamsString = (params) => {
	return getAllowedParamsString(params, ['utm_', 'afc']);
};

export const getAllowedParamsString = (params, allowedPrefixesArr = []) => {
	if (!params) return '';
	try {
		return Object.entries(params)
			.filter(([key]) => allowedPrefixesArr.some((allowedPrefix) => key.startsWith(allowedPrefix)))
			.map((pair) => pair.map(encodeURIComponent).join('='))
			.join('&');
	} catch (error) {
		notifyError(`Error trying to exectue getAllowedParamsString: ${error}`, {
			metadata: {
				query: params,
			},
		});
		return '';
	}
};
