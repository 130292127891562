export const isCurrentUserTheTopOfferer = ({
	currentDealershipOrAnySubordinateIdThatOffered,
	best_offer_offerer_dealership_id,
	loggedUser = {},
}) => {
	const { dealership_id: dealershipId, dealerships_where_user_is_master_buyer: dealershipsWhereUserIsMasterBuyer } =
		loggedUser;
	const dealershipsIds =
		dealershipsWhereUserIsMasterBuyer?.length ? dealershipsWhereUserIsMasterBuyer : [dealershipId];

	return (
		(best_offer_offerer_dealership_id &&
			Number(currentDealershipOrAnySubordinateIdThatOffered) === Number(best_offer_offerer_dealership_id)) ||
		dealershipsIds.includes(Number(best_offer_offerer_dealership_id))
	);
};

export const isMasterBuyerInBehalfOf = ({ currentDealershipOrAnySubordinateIdThatOffered, loggedUser }) => {
	return currentDealershipOrAnySubordinateIdThatOffered !== loggedUser.dealership_id ?
			currentDealershipOrAnySubordinateIdThatOffered
		:	null;
};
