import { Body, Button, FormControl, FormError, Headline, Input } from '@Backlot-Cars/archie';
import { BlcLoader } from 'components/BlcLoader';
import FormContainer from 'components/FormContainer';
import STATUS_CODES from 'constants/statusCodes';
import OktaService from 'helpers/okta';
import { getQueryParams } from 'helpers/queryParams';
import { getPathFromURL, getUtmAndAFCParamsString } from 'helpers/url';
import { useTranslation } from 'next-i18next';
import Router from 'next/router';
import AuthFormActions from 'partials/shared/user_form/_auth_form_actions';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import UserSessionService from 'services/navigation/userSession.service';
import OktaExistingSessionBanner from './okta-existing-session-banner';
import { Grouper } from './styles';

const SectionLanding = (props) => {
	const { t } = useTranslation(['common', 'signin']);
	const [error, setError] = useState();
	const [loading, setLoading] = useState(false);
	const { passwordForgot, signUpUrl, refererUrl } = props;
	const queryParamsToForward = getUtmAndAFCParamsString(getQueryParams(refererUrl));
	const errorMessage = t('common:error');
	const {
		register,
		handleSubmit,
		formState: { errors },
		getValues,
	} = useForm();

	const checkOktaProfile = async ({ username, password }) => {
		let useOktaLogin;
		try {
			const response = await UserSessionService.instance.checkOktaProfile({ username, password });
			if (response?.status === STATUS_CODES.OK) useOktaLogin = true;
		} catch {
			useOktaLogin = false;
		}
		return useOktaLogin;
	};

	const redirectAfterLogin = (locationFromServer) => {
		if (refererUrl) {
			location.replace(getPathFromURL(refererUrl, '/home'));
		} else {
			Router.push(getPathFromURL(locationFromServer || '/home', '/home'));
		}
	};

	const loginWithRails = async (password, email) => {
		try {
			const result = await UserSessionService.instance.signIn(email, password, queryParamsToForward);
			redirectAfterLogin(result?.headers?.location);
			return true;
		} catch (error) {
			setError(error?.error?.message || errorMessage);
		}
	};

	const loginWithOkta = async (password, email) => {
		const { success, errorMessage } = await OktaService.getInstance().loginUsingOurForm(
			email,
			password,
			queryParamsToForward,
		);
		if (success) {
			redirectAfterLogin();
		} else {
			setError(errorMessage);
		}
		return success;
	};

	const login = async () => {
		setLoading(true);
		setError('');

		const values = getValues();
		const { email, password } = values;

		const useOktaLogin = await checkOktaProfile({ username: email, password });
		const successfulLogin =
			useOktaLogin ? await loginWithOkta(password, email) : await loginWithRails(password, email);

		if (!successfulLogin) setLoading(false);
	};

	const onSubmit = () => {
		if (!loading) login();
	};

	const onKeyDown = (event) => {
		if (event.key === 'Enter') {
			onSubmit();
		}
	};

	return (
		<>
			<FormContainer>
				<Grouper gap={16}>
					<Headline size="H3">{t('signin:landing.content.title')}</Headline>
					<Body>
						{t('signin:landing.content.actions.signUp')}{' '}
						<Button variant="link" href={signUpUrl}>
							{t('signin:landing.content.actions.requestInvite')}
						</Button>
					</Body>
				</Grouper>

				<OktaExistingSessionBanner refererUrl={refererUrl} setLoading={setLoading} />

				<Grouper gap={24}>
					<FormControl label={t('signin:landing.content.fields.emailUsername')} formErrors={errors} required>
						<Input
							maxLength="255"
							id="email"
							{...register('email', {
								required: t('common:emailUsernameRequired'),
							})}
							onKeyDown={onKeyDown}
						/>
					</FormControl>
					<FormControl label={t('signin:landing.content.fields.password')} formErrors={errors} required>
						<Input
							type="password"
							autocomplete="off"
							maxLength="255"
							id="password"
							{...register('password', {
								required: t('common:passwordRequired'),
							})}
							onKeyDown={onKeyDown}
						/>
					</FormControl>
				</Grouper>

				{loading && <BlcLoader size="larger" />}
				{error && <FormError>{error}</FormError>}

				<AuthFormActions
					linkUrl={passwordForgot}
					linkText={t('signin:landing.content.fields.forgot')}
					submitText={t('signin:landing.content.actions.logIn')}
					onClickSubmit={handleSubmit(onSubmit)}
				/>
			</FormContainer>
		</>
	);
};

export default SectionLanding;
